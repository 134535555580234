import React from 'react';

import classes from './BentoBox.module.scss';

const BentoBox = () => {
    return (
        <div className={classes['bento']}>
            <div className={classes['box1']}>
                <h5>E-Learning <br />Courses</h5>
            </div>
            <div className={classes['box2']}>
                <h5>
                    Standard <br />Certificates
                </h5>
            </div>
            <div className={classes['box3']}>
                <h5>
                    Free <br /> Consultation<br /> Meetings
                </h5>
            </div>
            <div className={classes['box4']}>
                <h5>
                    Career <br /> Path <br /> Guide
                </h5>
            </div>
            <div className={classes['box5']}>
                <h5>
                    Skill Tree
                </h5>
            </div>
            <div className={classes['box6']}>
                <h5>
                    Public Profile <br />& Resume Builder
                </h5>
            </div>
            <div className={classes['box7']}>
                <h5>Cover Letter <br />Builder</h5>
            </div>
        </div>
    );
};

export default BentoBox;