import React from 'react';
import dayjs from "dayjs";

import CircleItemIcon from 'assets/icons/circle-item.svg?url';

import classes from './TrialRoadMap.module.scss';

const TrialRoadMap = ({ trialDays }) => {
    const today = dayjs(); // Get today's date
    const endTrialDate = today.add(trialDays, "day").format("DD MMM");
    const subscriptionStartDate = today.add(trialDays + 1, "day").format("DD MMM");
    return (
        <div className={classes.wrapper}>
            <div className='d-flex align-items-start gap-2'>
                <img src={CircleItemIcon} />
                <div>
                    <h3>Today <span>Trial starts</span></h3>
                    <p>
                        Full access to all premium pharmuni features.
                        You can cancel any time.
                    </p>
                </div>
            </div>
            <div className='d-flex align-items-start gap-2'>
                <img src={CircleItemIcon} />
                <div>
                    <h3>{endTrialDate} <span>Trial ends</span></h3>
                    <p>
                        We’ll send you a reminder before your Pharmuni Premium trial ends
                    </p>
                </div>
            </div>
            <div className='d-flex align-items-start gap-2'>
                <img src={CircleItemIcon} />
                <div>
                    <h3>{subscriptionStartDate} <span>Subscription starts</span></h3>
                    <p>
                    Your subscription begins when you provide a payment method during or after your free trial; otherwise, you won't be charged.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default TrialRoadMap;