
import React, {useEffect} from 'react';

import ShadowBox from '@core/components/shadowbox/ShadowBox';
import ActionButton from '@core/components/actionbutton/ActionButton';
import BentoBox from 'components/pages/plans/payment/subscriptionpaymentstepper/steps/bentobox/BentoBox';

import classes from './Step1.module.scss';

const Step1 = ({ data, setCurrentStep }) => {

    useEffect(() => {
        if(data.available_trial_days === 0)
            setCurrentStep(2)
      }, [data]);

    return (
        <div className={classes.wrapper}>
            <div className='row'>
                <div className='col-md-7 col-12'>
                    <ShadowBox>
                        <h3>Unlock Productivity for Your Career Life!</h3>
                        <h4><span>Industry Recognized Certificates:</span> "Boost Your Credentials"</h4>
                        <h4><span>E-Learning Courses:</span> "Learn from the Best"</h4>
                        <h4><span>Public Profile & Resume Builder:</span> "Stand Out Professionally"</h4>
                        <h4><span>Cover Letter Builder:</span> "Craft the Perfect Introduction"</h4>
                        <h4><span>Career Path Guide:</span> "Plan Your Next Move"</h4>
                        <h4><span>Skill Tree:</span> "Visualize Your Skillset"</h4>
                        <h4><span>Mentor Meetings:</span> "Get Advice from Industry Experts"</h4>
                        <p><span>Cancel any time,</span> we’ll remind you 1 day before trial ends.</p>
                    </ShadowBox>
                    <ActionButton kind='secondary_main' onClick={() => setCurrentStep(2)} >Try Free Trial</ActionButton>
                </div>
                <div className='col-md-5 col-12 d-md-block d-none'>
                    <div className={classes['bento-wrapper']}>
                        <ShadowBox>
                            <BentoBox />
                        </ShadowBox>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default Step1;