import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import UseToast from 'hooks/use-toast';
import { getErrorText } from 'utils/geterrortext';

import { UpdateFullnameService } from 'services/auth';

import TextInput from '@core/components/textinput/TextInput';
import ShadowBox from '@core/components/shadowbox/ShadowBox';
import ActionButton from '@core/components/actionbutton/ActionButton';
import TrialRoadMap from 'components/pages/plans/payment/subscriptionpaymentstepper/steps/trialroad/TrialRoadMap';

import classes from './Step3.module.scss';




const Step3 = ({ data, setCurrentStep }) => {
    const { toast, ToastContainer } = UseToast();
    const {
        register,
        handleSubmit,
        getValues: getValuesName,
        formState: { errors, isDirty, isValid }
    } = useForm({ mode: 'onChange' });

    const { mutate: updateFullName } = useMutation(UpdateFullnameService, {
        onSuccess: () => {
            setCurrentStep(4);
        },
        onError: (error) => {
            toast(getErrorText(error), 'Alert');
        }
    });

    const handlePayment = () => {
        if (!data?.is_name_verified) {
            handleSubmit((nameData) =>
                updateFullName(nameData)
            )();

        } else {
            setCurrentStep(4);
        }
    }

    useEffect(() => {
        if (data.user.is_name_verified)
            if (data.user.is_name_verified)
                setCurrentStep(4);
    }, [data]);



    return (
        <>
            <ToastContainer />
            <div className={data.available_trial_days > 0 ? `${classes.wrapper} ${classes.trial}` : `${classes.wrapper} `}>
                <div className='row'>
                    <div className={data.available_trial_days > 0 ? 'col-md-7 col-12' : 'col-12'}>
                        <ShadowBox>
                            <h3 className='mb-4 mt-2'>Add your name</h3>
                            <p className='mb-4'>We need your name to use in your account,  your certificate, public profile and cover letter.</p>
                            <form>
                                <TextInput
                                    validation={{
                                        required: true,
                                        validate: value => value.trim() !== ''
                                    }}
                                    input={{
                                        type: 'text',
                                        label: 'First Name',
                                        name: 'first_name',
                                        placeholder: 'First Name',
                                        id: 'first_name'
                                    }}
                                    errors={errors}
                                    register={register}
                                />
                                <TextInput
                                    validation={{
                                        required: true,
                                        validate: value => value.trim() !== ''
                                    }}
                                    input={{
                                        type: 'text',
                                        label: 'Last Name',
                                        name: 'last_name',
                                        placeholder: 'Last Name',
                                        id: 'last_name'
                                    }}
                                    errors={errors}
                                    register={register}
                                />
                                <label className={`${classes['verify-name-checkbox']} `} htmlFor="verify">
                                    <p>
                                        Your name will appear on your Certificates and Career assets. Once submitted, it cannot be changed. Please ensure it's correct.
                                    </p>
                                    <input
                                        type="checkbox"
                                        name="verify"
                                        id="verify"
                                        {...register('verify', {
                                            required: true
                                        })}
                                    />
                                    <span></span>
                                </label>
                            </form>
                        </ShadowBox>
                        {data.available_trial_days === 0 && (
                            <ActionButton
                                kind='secondary_main'
                                onClick={handlePayment}
                                disabled={(!isDirty || !isValid)}
                            >
                                Next
                            </ActionButton>
                        )}

                    </div>
                    {data.available_trial_days > 0 && (
                        <div className='col-md-5 col-12 '>
                            <span className='d-md-block d-none'>
                                <ShadowBox >
                                    <TrialRoadMap trialDays={data.available_trial_days} />
                                </ShadowBox>
                            </span>
                            <ActionButton
                                kind='secondary_main'
                                onClick={handlePayment}
                                disabled={(!isDirty || !isValid)}
                            >
                                Next
                            </ActionButton>
                        </div>
                    )}
                </div>
            </div>
        </>

    );
};

export default Step3;