import { useState, useCallback } from 'react';

const UseModal = (backDropClose = true) => {
	const [show, setIsShowing] = useState(false);

	const showModal = useCallback(() => {
		setIsShowing(true);
	}, []);

	const destroyModal = useCallback((event, reason) => {
		if (!backDropClose && reason === 'backdropClick') {
            return;
        }
		setIsShowing(false);
	}, []);

	return {
		show,
		showModal,
		destroyModal
	};
};

export default UseModal;
