import React from 'react';

import ShadowBox from '@core/components/shadowbox/ShadowBox';
import ActionButton from '@core/components/actionbutton/ActionButton';
import BentoBox from 'components/pages/plans/payment/subscriptionpaymentstepper/steps/bentobox/BentoBox';
import { TextField } from '@mui/material';

import TickIcon from 'assets/icons/tick.svg';
import RadioSelectedIcon from '@core/assets/icons/radio-selected.svg';
import RadioDeselectedIcon from '@core/assets/icons/radio-deselected.svg';
import NotificationIcon from 'assets/icons/notification-icon.svg';

import classes from './step2.module.scss';

const Step2 = ({
    data,
    period,
    setPeriod,
    hasDiscount,
    setHasDiscount,
    discountErrorMsg,
    setDiscountErrorMsg,
    setDiscountCode,
    discountCode,
    refetch,
    setCurrentStep
}) => {
    const discountCodeChangeHandler = (e) => {
        setDiscountCode(e.target.value);
    };
    const handleValidateDiscount = () => {
        if (discountCode !== '')
            refetch();
    }
    const handleRemoveDiscount = () => {
        setDiscountCode('');
        setHasDiscount(false);
    }
    return (
        <div className={classes.wrapper}>
            <div className='row'>
                <div className='col-md-7 col-12'>
                    <ShadowBox>
                        {data.available_trial_days > 0 && (
                            <div className={classes['trial-data']}>
                                <div className='d-flex align-items-center gap-2'>
                                    <TickIcon /> <h4>{data.available_trial_days}-day Free Trial, cancel any time.</h4>
                                </div>
                                <div className='d-flex align-items-center gap-2'>
                                    <TickIcon /> <h4>We’ll remind you before your trial ends.</h4>
                                </div>
                            </div>
                        )}
                        {(data.available_trial_days === 0 && data.trial_available) && (
                            <div className={`${classes['discount-message']} d-flex align-items-center gap-2`}>
                                <NotificationIcon /> <h4>You can proceed with your discounted plan or remove the code to access the free trial instead.</h4>
                            </div>
                        )}

                        <div className={classes['period-cart']} onClick={() => setPeriod('Annual')}>
                            <div className='d-flex flex-wrap align-items-center justify-content-between'>
                                <div>
                                    {period === 'Annual' ? <RadioSelectedIcon /> : <RadioDeselectedIcon />}
                                    <span className={classes['period-name']}>
                                        Annual
                                    </span>
                                    {data.yearly.saving && (
                                        <span className={classes['save']}>
                                            Save {data.yearly.currency.symbol} {data.yearly.saving}
                                        </span>
                                    )}
                                </div>
                                <span className={classes['popular']}>
                                    Most Popular
                                </span>
                            </div>
                            <div className={classes['price']}>
                                <span className={data.yearly.discounted_price ? classes['real-price'] : ''}>{data.yearly.currency.symbol}{data.yearly.price}</span>
                                {data.yearly.discounted_price && (
                                    <span className={classes['discounted-price']}>{data.yearly.currency.symbol}{data.yearly.discounted_price}</span>
                                )}
                            </div>
                        </div>
                        <div className={classes['period-cart']} onClick={() => setPeriod('Monthly')}>
                            <div className='d-flex flex-wrap align-items-center justify-content-between'>
                                <div>
                                    {period === 'Monthly' ? <RadioSelectedIcon /> : <RadioDeselectedIcon />}
                                    <span className={classes['period-name']}>
                                        Monthly
                                    </span>
                                    {data.monthly.saving && (
                                        <span className={classes['save']}>
                                            Save {data.monthly.currency.symbol} {data.monthly.saving}
                                        </span>
                                    )}
                                </div>
                            </div>
                            <div className={classes['price']}>
                                <span className={data.monthly.discounted_price ? classes['real-price'] : ''}>{data.monthly.currency.symbol}{data.monthly.price}</span>
                                {data.monthly.discounted_price && (
                                    <span className={classes['discounted-price']}>{data.monthly.currency.symbol}{data.monthly.discounted_price}</span>
                                )}
                            </div>
                        </div>



                        <div className={`${classes['discount']} d-flex flex-column`}>
                            <div>
                                <div className="d-flex flex-row align-items-center gap-2">
                                    <TextField
                                        type="text"
                                        autoComplete="discount-code"
                                        id="discount-code"
                                        name="discount-code"
                                        placeholder="Enter your code here"
                                        variant="outlined"
                                        sx={{ '& > .MuiInputBase-root': { borderRadius: '8px', backgroundColor: `${hasDiscount ? '#E6F0DA' : discountErrorMsg ? '#FFE5E5' : '#fff'}` } }}
                                        onChange={discountCodeChangeHandler}
                                        value={discountCode}
                                        disabled={hasDiscount}
                                        // error={error}
                                    />
                                    <ActionButton
                                        kind="blank"
                                        onClick={(discountErrorMsg === null && data?.monthly?.discount_message && hasDiscount) ? handleRemoveDiscount : handleValidateDiscount}>
                                        {(discountErrorMsg === null && data?.monthly?.discount_message && hasDiscount) ? 'Remove' : 'Apply'}

                                    </ActionButton>
                                </div>
                                {(discountErrorMsg === null && hasDiscount) ? (
                                    <p className={`${classes['discount-msg']}`}>
                                        {period === 'Monthly' && data?.monthly?.discount_message && (
                                            data?.monthly?.discount_message
                                        )}
                                        {period === 'Annual' && data?.yearly?.discount_message && (
                                            data?.yearly?.discount_message
                                        )}
                                    </p>
                                ) : (
                                    <div className={classes['discount-error-message']}>
                                        {discountErrorMsg}
                                    </div>
                                )}
                            </div>
                        </div>
                    </ShadowBox>
                    <ActionButton kind='secondary_main' onClick={() => setCurrentStep(3)} >Next</ActionButton>
                </div>
                <div className='col-md-5 col-12 d-md-block d-none'>
                    <div className={classes['bento-wrapper']}>
                        <ShadowBox>
                            <BentoBox />
                        </ShadowBox>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default Step2;