import React, { useState } from 'react';

import UseModal from 'hooks/use-modal';
import UseToast from 'hooks/use-toast';

import Modal from '@core/components/Modal/Modal';
import ActionButton from '@core/components/actionbutton/ActionButton';
import LinkButton from '@core/components/linkbutton/LinkButton';
import SubscriptionPaymentWrapper from 'components/pages/plans/payment/SubscriptionPaymentWrapper';
import SubscriptionPaymentStepper from 'components/pages/plans/payment/subscriptionpaymentstepper/SubscriptionPaymentStepper';

import { MOBILE_SLIDE_UP } from '@core/constants/ModalTypes';

import classes from './GoPremiumBanner.module.scss';




const GoPremiumBanner = ({ type = 'premium', avalaibleTrialDays = 0 }) => {
    const { show, showModal, destroyModal } = UseModal();
    const [period, setPeriod] = useState('Annual');
    const { ToastContainer, toast } = UseToast();
    return (
        <>
            <ToastContainer />
            <div className={type === 'premium' ? classes['spacer'] : classes['trial-spacer']}></div>
            <div className={`${classes['banner-wrapper']} ${type === 'premium' ? classes['premium-banner'] : ''}`}>
                <div className='container'>
                    <div className='row'>
                        <div className='col-xl-9 col-12 d-flex justify-content-center align-items-center'>
                            {type === 'premium' ?
                                <h3>This feature needs premium subscription. Go premium to unlock all features.</h3>
                                :
                                <h3>
                                    Unlock all premium courses and features on Pharmuni with our {avalaibleTrialDays}-day FREE trial—no credit card needed!
                                </h3>
                            }

                        </div>
                        <div className='col-xl-3 col-12  d-flex justify-content-center align-items-center'>
                            {type === 'premium' ?
                                <LinkButton kind='primary-color' to={'/plans'}>
                                    Go Premium
                                </LinkButton>
                                :
                                <ActionButton onClick={() => showModal()}>Start Now!</ActionButton>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                type={MOBILE_SLIDE_UP}
                title=""
                show={show}
                closeHandler={destroyModal}
                header={true}
            >
                {/* <div className={classes['payment-modal']}>
                    <SubscriptionPaymentWrapper
                        closeHandler={destroyModal}
                        toast={toast}
                        period={period}
                        setPeriod={setPeriod}
                    />
                </div> */}
                <SubscriptionPaymentStepper
                    closeHandler={destroyModal}
                    toast={toast}
                    period={period}
                    setPeriod={setPeriod}
                />

            </Modal>
        </>
    );
};

export default GoPremiumBanner;