import React from 'react';

import classes from './ShadowBox.module.scss';

const ShadowBox = ({children, type=''}) => {
    return (
        <div className={`${classes['shadow-box']} ${classes[type]}`}>
            {children}
        </div>
    );
};

export default ShadowBox;