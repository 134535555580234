import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { getAccessToken } from 'utils/authentication';
import { getErrorText } from 'utils/geterrortext';
import { useQuery, useMutation } from 'react-query';
import { getPersonalizedSubscriptionPlans } from 'services/payments';
import { PROFILE_DETAIL_ERROR, INVALID_DISCOUNT_CODE } from '@core/constants/Errors';

import Step1 from 'components/pages/plans/payment/subscriptionpaymentstepper/steps/Step1';
import Step2 from 'components/pages/plans/payment/subscriptionpaymentstepper/steps/Step2';
import Step3 from 'components/pages/plans/payment/subscriptionpaymentstepper/steps/Step3';
import SimpleBack from '@core/assets/icons/simple-back.svg';

import classes from './SubscriptionPaymentStepper.module.scss';
import ActionButton from '@core/components/actionbutton/ActionButton';
import Step4 from './steps/Step4';


const SubscriptionPaymentStepper = ({ closeHandler, toast, period, setPeriod }) => {
    const router = useRouter();
    const [currentStep, setCurrentStep] = useState(1);
    const [discountCode, setDiscountCode] = useState('');
    const [discountErrorMsg, setDiscountErrorMsg] = useState(null);
    const [hasDiscount, setHasDiscount] = useState(false);
    const { data, isLoading, error, refetch } = useQuery(['get-subscription-plans-personalized', getAccessToken()],
        () => getPersonalizedSubscriptionPlans(discountCode), {
        onError: (e) => {
            setHasDiscount(false);
            if (e?.response?.status === +INVALID_DISCOUNT_CODE) {
                setDiscountErrorMsg(getErrorText(e));
            } else if (e.response.status === 428) {
                router.replace('/verification');
            }
            else {
                toast(getErrorText(e), 'Alert');
            }
        },
        onSuccess: () => {
            setDiscountErrorMsg(null);
            if (discountCode !== '')
                setHasDiscount(true);
        }
    });
    useEffect(() => {
        if (discountCode === '') {
            refetch();
        }
    }, [discountCode]);

    const showActiveStep = () => {
        switch (currentStep) {
            case 1:
                return <Step1 data={data.data} setCurrentStep={setCurrentStep} />
            case 2:
                return <Step2
                    data={data.data}
                    period={period}
                    setPeriod={setPeriod}
                    hasDiscount={hasDiscount}
                    setHasDiscount={setHasDiscount}
                    discountErrorMsg={discountErrorMsg}
                    setDiscountErrorMsg={setDiscountErrorMsg}
                    discountCode={discountCode}
                    setDiscountCode={setDiscountCode}
                    refetch={refetch}
                    setCurrentStep={setCurrentStep}
                />
            case 3:
                return <Step3 data={data.data} setCurrentStep={setCurrentStep} />
            case 4:
                return <Step4
                    data={data.data}
                    period={period}
                    discountCode={discountCode}
                    setDiscountErrorMsg={setDiscountErrorMsg}
                    toast={toast}
                />
        }
    }

    const backHandler = () => {
        if (currentStep === 4)
            setCurrentStep(2);
        else
            setCurrentStep(currentStep === 1 ? 0 : currentStep - 1)
    };

    const backButton = () => {
        if (currentStep === 2) {
            if (data?.data?.trial_available)
                return <button className='btn d-flex flex-row p-0' onClick={backHandler} ><SimpleBack /></button>
        }
        else if (currentStep > 1)
            return <button className='btn d-flex flex-row p-0' onClick={backHandler} ><SimpleBack /></button>

    };

    return (
        <div className={classes.wrapper}>
            {data && !isLoading && (
                <>
                    <div className='d-flex align-items-center gap-2 mb-3'>
                        {backButton()}
                        {/* {((currentStep > 1) && (currentStep===2 && data.data.trial_available)) &&
                            
                        } */}
                        <h3>
                            {data.data?.available_trial_days > 0 ? 'Try Pharmuni Pro for Free' : 'Subscription'}
                        </h3>
                    </div>
                    {showActiveStep()}
                </>
            )}


        </div>
    );
};

export default SubscriptionPaymentStepper;