import React, { useState } from 'react';

import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useRouter } from 'next/router';
import useWindowSize from 'hooks/use-windowSize';
import { SubscriptionService } from 'services/payments';
import { MOBILE_SLIDE_UP } from '@core/constants/ModalTypes';
import { getErrorText } from 'utils/geterrortext';
import UseModal from 'hooks/use-modal';

import Modal from '@core/components/Modal/Modal';
import CartStripe from 'components/pages/cart/CartStripe';
import ShadowBox from '@core/components/shadowbox/ShadowBox';
import ActionButton from '@core/components/actionbutton/ActionButton';
import TrialRoadMap from 'components/pages/plans/payment/subscriptionpaymentstepper/steps/trialroad/TrialRoadMap';
import CartCountrySelect from 'components/pages/cart/CartCountrySelect';
import BillingInformationData from 'components/common/BillingInformationData/BillingInformationData';
import TextInput from '@core/components/textinput/TextInput';

import EditIcon from 'assets/icons/edit-icon.svg';

import classes from './Step4.module.scss';



const Step4 = ({ data, period, discountCode, setDiscountErrorMsg, toast }) => {
    const router = useRouter();
    const [selectedCountry, setSelectedCountry] = useState(data?.user?.country_iso);
    const [editMode, setEditMode] = useState(false);
    const { mobileDevice } = useWindowSize();
    const {
        show: showStripe,
        destroyModal: destroyStripeModal,
        showModal: showStripeModal
    } = UseModal();
    const {
        register: reg2,
        handleSubmit: handl2,
        setValue,
        formState: { errors: errors2, isDirty: isDirty2, isValid: isValid2 }
    } = useForm({
        mode: 'onChange',
        shouldFocusError: false,
        defaultValues: {
            country_iso: data?.user?.country_iso,
            postal_code: data?.user?.postal_code,
            city: data?.user?.city,
            address_line_1: data?.user?.address_line_1,
            address_line_2: data?.user?.address_line_2
        }
    });

    const {
        data: dataSubscription,
        mutate: checkoutServiceCallSubscription,
        isLoading: checkoutServiceCallLoadingSubscription
    } = useMutation(SubscriptionService, {
        onSuccess: (dataSubscription) => {
            if (dataSubscription?.data?.payment_intent_client_secret) {
                showStripeModal();
                setDiscountErrorMsg(null);
            }
            else
                router.replace('/payment-result-subscription?redirect_status=trial')

        },
        onError: (error) => {
            if (error?.response?.status === +INVALID_DISCOUNT_CODE) {
                setDiscountErrorMsg(getErrorText(error));
            } else if (error?.response?.status === +BAD_REQUEST && error?.response?.data?.data?.discount_code) {
                setDiscountErrorMsg(error?.response?.data?.data?.discount_code[0]);
            } else if (error?.response?.data?.data?.billing_address) {
                toast('Please check your billing information.', 'Alert');
            }
            else {
                toast(getErrorText(error), 'Alert');
            }
        }
    });

    const getPlanId = () => {
        return (period === 'Annual' ? 'yearly' : 'monthly')
    };

    const getDiscountCode = () => {
        return (discountCode === '' ? null : discountCode);
    }

    const paymentHandler = () => {
        handl2((address) =>
            checkoutServiceCallSubscription({
                plan_id: getPlanId(),
                discount_code: getDiscountCode(),
                billing_address: {
                    country_iso: selectedCountry?.iso2 ? selectedCountry?.iso2 : selectedCountry,
                    address_line_2: address.address_line_2?.trim() || null,
                    postal_code: address.postal_code?.trim(),
                    city: address.city?.trim(),
                    address_line_1: address.address_line_1?.trim()
                }
            })
        )();
    };



    return (
        <>
            <div className={data.available_trial_days > 0 ? `${classes.wrapper} ${classes.trial}` : `${classes.wrapper} `}>
                <div className='row'>
                    <div className={data.available_trial_days > 0 ? 'col-md-7 col-12' : 'col-12'}>
                        <ShadowBox>
                            <div className={classes['address-wrapper']}>
                                <h4 >Add billing information</h4>
                                {((data.user.country_iso || data.user.city || data.user.postal_code || data.user.address_line_1) && !editMode) ? (
                                    <>
                                        <div
                                            className={`d-flex flex-row align-items-center justify-content-end mb-3 ${classes['update-billing-btn']}`}>
                                            <ActionButton kind='secondary' onClick={() => setEditMode(true)}>
                                                <EditIcon />
                                            </ActionButton>
                                        </div>
                                        <div className='mb-4 mt-4 pt-2'>
                                            <BillingInformationData billingAddress={data.user} />
                                        </div>
                                    </>

                                ) : (
                                    <form
                                        className={`row ${classes['billing-address-form']}`}
                                    >
                                        <CartCountrySelect
                                            validation={{
                                                required: true
                                            }}
                                            input={{
                                                type: 'text',
                                                label: 'Country',
                                                name: 'country',
                                                placeholder: '',
                                                id: 'country',
                                                autoComplete: 'off'
                                            }}
                                            errors={errors2}
                                            register={reg2}
                                            selectedCountry={selectedCountry}
                                            setSelectedCountry={setSelectedCountry}
                                            mobileDevice={mobileDevice}
                                            setValue={setValue}
                                            className={`col-12 col-md-6`}
                                        />
                                        <TextInput
                                            validation={{
                                                required: true,
                                                validate: value => value.trim() !== ''
                                            }}
                                            input={{
                                                type: 'text',
                                                label: 'City',
                                                name: 'city',
                                                placeholder: 'City',
                                                id: 'city'
                                            }}
                                            errors={errors2}
                                            register={reg2}
                                            hasErrorMsg={false}
                                            className={`col-12 col-md-6`}
                                        />
                                        <TextInput
                                            validation={{
                                                required: true,
                                                validate: value => value.trim() !== ''
                                            }}
                                            input={{
                                                type: 'text',
                                                label: 'Address Line 1',
                                                name: 'address_line_1',
                                                placeholder: 'Address Line 1',
                                                id: 'address_line_1'
                                            }}
                                            errors={errors2}
                                            register={reg2}
                                            hasErrorMsg={false}
                                            className={`col-12`}
                                        />
                                        <TextInput
                                            input={{
                                                type: 'text',
                                                label: 'Address Line 2',
                                                name: 'address_line_2',
                                                placeholder: 'Address Line 2',
                                                id: 'address_line_2'
                                            }}
                                            errors={errors2}
                                            register={reg2}
                                            hasErrorMsg={false}
                                            className={`col-12`}
                                        />
                                        <TextInput
                                            validation={{
                                                required: true,
                                                validate: value => value.trim() !== ''
                                            }}
                                            input={{
                                                type: 'text',
                                                label: 'Postal Code',
                                                name: 'postal_code',
                                                placeholder: 'Postal Code',
                                                id: 'postal_code'
                                            }}
                                            errors={errors2}
                                            register={reg2}
                                            hasErrorMsg={false}
                                            className={`col-12`}
                                        />
                                    </form>
                                )}
                            </div>
                        </ShadowBox>
                        {data.available_trial_days === 0 && (
                            <ActionButton
                                kind='secondary_main'
                                onClick={paymentHandler}
                                loading={checkoutServiceCallLoadingSubscription}
                            >
                                {data.available_trial_days > 0 ? 'Confirm and Start your Free Trial' : 'Proceed to payment'}
                            </ActionButton>
                        )}

                    </div>
                    {data.available_trial_days > 0 && (
                        <div className='col-md-5 col-12'>
                            <span className='d-md-block d-none'>
                                <ShadowBox>
                                    <TrialRoadMap trialDays={data.available_trial_days} />
                                </ShadowBox>
                            </span>
                            <ActionButton
                                kind='secondary_main'
                                onClick={paymentHandler}
                                loading={checkoutServiceCallLoadingSubscription}
                            >
                                {data.available_trial_days > 0 ? 'Confirm and Start your Free Trial' : 'Proceed to payment'}
                            </ActionButton>
                        </div>
                    )}
                </div>
            </div>
            <Modal show={showStripe} type={MOBILE_SLIDE_UP} closeHandler={destroyStripeModal}>
                <div className={`${classes['stripe-wrapper-modal']} d-flex flex-column gap-2`}>
                    <CartStripe
                        clientSecret={dataSubscription?.data?.payment_intent_client_secret}
                        invoice_amount={dataSubscription?.data?.invoice_amount}
                        currency={dataSubscription?.data?.currency}
                    />
                </div>
            </Modal>
        </>
    );
};

export default Step4;